import React from "react";
import { Helmet } from "react-helmet";
import Header from "../../components/Header/header";
import Navbar from "../../components/Navbar/navbar";
import Footer from "../../components/Footer/footer";

export default function PrivacyPolicy() {
    return (
        <>
            <Helmet>
                <title>Privacy Policy | SmartGov</title>
            </Helmet>
            <Navbar />
            <Header
                title="Privacy Policy"
                description="Privacy Policy for Smart Government Products" />
            <div className="bg-white py-24 sm:py-32">
                <div className="mx-auto max-w-7xl px-6 lg:px-8">
                    <div className="mx-auto lg:mx-0 lg:max-w-none">
                        <div className="mx-auto lg:mx-0">
                            <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                                Privacy Policy for SmartGov Products
                            </h1>
                            <p className="mt-4 text-lg leading-8 text-gray-600">
                                By using any of the SmartGov products (Go.NUG, Edu.NUG, Metafind, Ask.NUG, PostMaster, Data.NUG), you agree to the following terms and conditions:
                            </p>
                            <ol className="mt-4 list-decimal list-inside text-lg text-gray-600">
                                <li className="mt-4"><strong>Data Collection</strong>:
                                    <ul className="list-disc list-inside ml-4">
                                        <li className="mt-4"><strong>Voluntary Data</strong>: We may collect personal information you provide voluntarily, such as your name and email address.</li>
                                        <li className="mt-4"><strong>Automatic Data (Go.NUG and Edu.NUG)</strong>: In addition to voluntary data, Go.NUG and Edu.NUG may automatically collect and store your IP address, browser information, operating system details, and country during your interaction with the platform.</li>
                                        <li className="mt-4"><strong>Analytics</strong>: We use Google Analytics to collect and analyze data on how you interact with our services. This information helps us improve our products and services. Google Analytics may collect data such as device information, location, and usage patterns. For Metafind, Ask.NUG, PostMaster, and Data.NUG, Google Analytics does not collect your IP address.</li>
                                    </ul>
                                </li>
                                <li className="mt-4"><strong>Encryption</strong>: All collected data, including your IP address (for Go.NUG and Edu.NUG), will be encrypted using industry-standard encryption protocols during the submission process.</li>
                                <li className="mt-4"><strong>Storage</strong>: The encrypted data will be securely stored in a dedicated database to which only authorized personnel have access.</li>
                                <li className="mt-4"><strong>Data Usage</strong>: The provided information, including your IP address (for Go.NUG and Edu.NUG), will be used solely for the intended purpose, such as unique data collection, system analysis, and improving service quality. We will not share your collected data with any third parties, including government officials, without your explicit consent, unless required by law.</li>
                                <li className="mt-4"><strong>Security Measures</strong>: We implement reasonable and appropriate security measures to safeguard all collected and encrypted information against unauthorized access, disclosure, alteration, and destruction.</li>
                                <li className="mt-4"><strong>Issue Tracking</strong>: We use Better Stack for issue tracking and system monitoring. Better Stack may collect data relevant to the performance and usage of our services, but it does not collect your IP address.</li>
                                <li className="mt-4"><strong>Retention Period</strong>: The collected data will be retained for the duration necessary to fulfill the purpose for which it was collected, or as required by applicable laws and regulations.</li>
                                <li className="mt-4"><strong>Your Rights</strong>: You have the right to request access, correction, or deletion of your collected information, including your IP address (for Go.NUG and Edu.NUG). To exercise these rights or for any concerns regarding your data, please contact us using the provided contact information.</li>
                                <li className="mt-4"><strong>Consent</strong>: By using any of the SmartGov products (Go.NUG, Edu.NUG, Metafind, Ask.NUG, PostMaster, Data.NUG), you consent to the collection, encryption, and storage of your name, email address, IP address (for Go.NUG and Edu.NUG), and other relevant information in accordance with these terms and conditions.</li>
                                <li className="mt-4"><strong>Updates</strong>: These terms and conditions may be updated from time to time, and any changes will be communicated to you through the contact information provided.</li>
                            </ol>
                            <p className="mt-6 text-lg leading-8 text-gray-600">
                                Please read these privacy terms and conditions carefully before using any of the SmartGov products. If you do not agree with any of the terms, please refrain from using these services.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}