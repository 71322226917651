import { useState } from "react";
import { Helmet } from "react-helmet";
import {
  Label,
  Listbox,
  ListboxButton,
  ListboxOption,
  ListboxOptions,
  Transition
} from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import React from "react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { postNow } from "../../hook/postNow";
import Model from "../../components/Model/model";
import Header from "../../components/Header/header";
import Navbar from "../../components/Navbar/navbar";
import Footer from "../../components/Footer/footer";

const agencies = [
  "---- Select an Agency ----",
  "Ministry of Defense",
  "Ministry of Health",
  "Ministry of Planning, Finance and Investment",
  "Ministry of Foreign Affairs",
  "Ministry of Education",
  "Ministry of Home Affairs and Immigration",
  "Ministry of Federal Union Affairs",
  "Ministry of Humanitarian Affairs and Disaster Management",
  "Ministry of Human Rights",
  "Ministry of Natural Resources and Environmental Conservation",
  "Ministry of International Cooperation",
  "Ministry of Women, Youths and Children Affairs",
  "Ministry of Labour",
  "Ministry of Justice",
  "Ministry of Communications, Information & Technology",
  "Ministry of Electricity & Energy",
  "Ministry of Commerce",
  "The president’s Office",
  "Prime Minister’s Office",
  "Press Office"
]; // replace with actual agencies

const people = [
  { id: 1, name: "-Select-" },
  { id: 2, name: "Ministry of Defense" },
  { id: 3, name: "Ministry of Health" },
  { id: 4, name: "Ministry of Planning, Finance and Investment" },
  { id: 5, name: "Ministry of Foreign Affairs" },
  { id: 6, name: "Ministry of Education" },
  { id: 7, name: "Ministry of Home Affairs and Immigration" },
  { id: 8, name: "Ministry of Federal Union Affairs" },
  { id: 9, name: "Ministry of Humanitarian Affairs and Disaster Management" },
  { id: 10, name: "Ministry of Human Rights" },
  {
    id: 11,
    name: "Ministry of Natural Resources and Environmental Conservation"
  },
  { id: 12, name: "Ministry of International Cooperation" },
  { id: 13, name: "Ministry of Women, Youths and Children Affairs" },
  { id: 14, name: "Ministry of Labour" },
  { id: 15, name: "Ministry of Justice" },
  { id: 16, name: "Ministry of Communications, Information & Technology" },
  { id: 17, name: "Ministry of Electricity & Energy" },
  { id: 18, name: "Ministry of Commerce" },
  { id: 19, name: "The president’s Office" },
  { id: 19, name: "Prime Minister’s Office" },
  { id: 19, name: "Press Secretary Team" }
];
// eslint-disable-next-line react-hooks/rules-of-hooks

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
export default function Support() {
  const [
    isRegisteringEducationInstitutionsClicked,
    setIsRegisteringEducationInstitutionsClicked
  ] = useState(false);
  const [accepted, setAccepted] = useState(false);

  const [isInputValid, setInputValid] = useState(true);

  const [issue, setIssue] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedAgency, setSelectedAgency] = useState<string | null>(null);
  const [agency, setAgency] = useState("");
  const [assist, setAssist] = useState("");
  const [officelDomain, setOfficeDomain] = useState("");
  const [selectedOption, setSelectedOption] = useState("push-everything");

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();

    // toast('Form Date Submitted Successfully ' + issue + ' ' + name + ' ' + email + ' ' + selectedAgency + ' ' + assist + ' ' + officelDomain)

    if (!name) {
      toast("Please fill out the name field");
    } else if (!email) {
      toast("Please fill out the email field");
    } else if (!selectedAgency && !isRegisteringEducationInstitutionsClicked) {
      toast("Please fill out the agency field");
    } else if (!officelDomain && isRegisteringEducationInstitutionsClicked) {
      toast("Please fill out the officelDomain field");
    } else if (!accepted) {
      toast("Please accept the terms and conditions");
    } else if (!issue) {
      toast("Please fill out the issue field");
    } else if (!assist) {
      toast("Please fill out the assist field");
    } else {
      setInputValid(true);
      // proceed with form submission
      const data = {
        name,
        email,
        selectedAgency: selectedAgency || "",
        issue,
        officelDomain,
        assist,
        timestamp: new Date().toLocaleString("en-US", {
          timeZone: "Asia/Yangon"
        })
      };
      postNow(data).then((res) => {
        if (res.code === 200) {
          setFormSubmitted(true);
        }
      });

      toast("Form Date Submitted Successfully");
    }
  };

  const handleClick = () => {
    setAccepted(!accepted);
  };

  const [selected, setSelected] = useState(people[3]);

  const setAssistData = (assistdata) => {
    setAssist(assistdata);
    setSelectedOption("push-everything");
  };

  // const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
  //     setSelectedAgency(event.target.value);
  // };

  return (
    <div>
      <Helmet>
        <title>Support | SmartGov</title>
      </Helmet>

      {formSubmitted ? <Model /> : ""}

      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <Navbar />

      <Header
        title="Support center"
        description="The support center for smart government products provides comprehensive assistance and solutions to ensure seamless functionality and user satisfaction."
      />

      <form onSubmit={handleSubmit}>
        <div className="mx-auto my-0 max-w-3xl rounded-lg border border-gray-300 bg-white p-10 shadow-2xl md:my-12 lg:my-12">
          <div className="space-y-12">
            <div className="flex items-center rounded-lg border bg-gray-100 p-4 shadow-inner">
              <img
                className="h-20 w-auto"
                src="https://nationalunitygovernment.b-cdn.net/assets/smartgov/smartgov-logo-2x.png"
                alt="SmartGov"
              />
              <div className="flex flex-col items-start">
                <h1 className="ml-3 text-2xl font-bold">SmartGov - Support</h1>
                <p className="ml-3">2 mins estimated time to complete</p>
              </div>
            </div>

            <p className="text-sm font-semibold leading-7 text-gray-900">
              Thank you for your interest in SmartGov! We greatly appreciate you
              taking the time to reach out to us.
            </p>

            <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
              <div className="col-span-full">
                <label
                  htmlFor="about"
                  className="text-base font-semibold leading-6 text-gray-900"
                >
                  Details of your issue / request / feedback *
                </label>
                <div className="mt-2">
                  <textarea
                    id="about"
                    name="about"
                    aria-describedby="about-description"
                    rows={3}
                    value={issue}
                    onChange={(e) => setIssue(e.target.value)}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                    defaultValue={""}
                  />
                </div>
                <p className="text-xs italic leading-6 text-gray-600">
                  Kindly provide a detailed description of your issue or request
                  to enhance our ability to assist you effectively.
                </p>
              </div>
              <div className="col-span-full">
                <fieldset>
                  <legend className="text-base font-semibold leading-6 text-gray-900">
                    Which service may we assist you? *
                  </legend>
                  <div className="mt-6 space-y-6">
                    <div className="grid grid-cols-2 gap-4">
                      <div className="flex items-center gap-x-3">
                        <input
                          id="push-everything"
                          name="push-notifications"
                          type="radio"
                          onClick={() => setIsRegisteringEducationInstitutionsClicked(false)}
                          onChange={() => setAssistData("for Go.NUG")}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="push-everything" className="block text-sm font-medium leading-6 text-gray-900">
                          for Go.NUG
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="RegisteringEducationEduNUG"
                          name="push-notifications"
                          type="radio"
                          onChange={() => setAssistData("for Edu.NUG")}
                          onClick={() => setIsRegisteringEducationInstitutionsClicked(true)}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="RegisteringEducationEduNUG" className="block text-sm font-medium leading-6 text-gray-900">
                          for Edu.NUG
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="RegisteringMetafind"
                          name="push-notifications"
                          type="radio"
                          onChange={() => setAssistData("for Metafind")}
                          onClick={() => setIsRegisteringEducationInstitutionsClicked(false)}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="RegisteringMetafind" className="block text-sm font-medium leading-6 text-gray-900">
                          for Metafind
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="RegisteringAskNUG"
                          name="push-notifications"
                          type="radio"
                          onChange={() => setAssistData("for Ask.NUG")}
                          onClick={() => setIsRegisteringEducationInstitutionsClicked(false)}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="RegisteringAskNUG" className="block text-sm font-medium leading-6 text-gray-900">
                          for Ask.NUG
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="RegisteringPostMaster"
                          name="push-notifications"
                          type="radio"
                          onChange={() => setAssistData("for PostMaster")}
                          onClick={() => setIsRegisteringEducationInstitutionsClicked(false)}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="RegisteringPostMaster" className="block text-sm font-medium leading-6 text-gray-900">
                          for PostMaster
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="RegisteringDataNUG"
                          name="push-notifications"
                          type="radio"
                          onChange={() => setAssistData("for Data.NUG")}
                          onClick={() => setIsRegisteringEducationInstitutionsClicked(false)}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="RegisteringDataNUG" className="block text-sm font-medium leading-6 text-gray-900">
                          for Data.NUG
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="forMetaMask"
                          name="push-notifications"
                          type="radio"
                          onClick={() => setIsRegisteringEducationInstitutionsClicked(false)}
                          onChange={() => setAssistData("for Meta Mask")}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="forMetaMask" className="block text-sm font-medium leading-6 text-gray-900">
                          for Meta Mask
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="push-issue"
                          name="push-notifications"
                          type="radio"
                          onClick={() => setIsRegisteringEducationInstitutionsClicked(false)}
                          onChange={() => setAssistData("Login Issues")}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="push-issue" className="block text-sm font-medium leading-6 text-gray-900">
                          Login Issues
                        </label>
                      </div>
                      <div className="flex items-center gap-x-3">
                        <input
                          id="push-accountblocking"
                          name="push-notifications"
                          type="radio"
                          onClick={() => setIsRegisteringEducationInstitutionsClicked(false)}
                          onChange={() => setAssistData("Temporary Account Blocking Request")}
                          className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                        />
                        <label htmlFor="push-accountblocking" className="block text-sm font-medium leading-6 text-gray-900">
                          Temporary Account Blocking Request
                        </label>
                      </div>
                    </div>
                    <div className="flex items-center gap-x-3">
                      <input
                        id="push-otherissue"
                        name="push-notifications"
                        type="radio"
                        onClick={() =>
                          setIsRegisteringEducationInstitutionsClicked(false)
                        }
                        onChange={() => setAssistData("Other")}
                        className="h-4 w-4 border-gray-300 text-indigo-600 focus:ring-indigo-600"
                      />
                      <label
                        htmlFor="push-otherissue"
                        className="block text-sm font-medium leading-6 text-gray-900"
                      >
                        Other
                      </label>
                    </div>
                    <div className="mt-2">
                      <div className="flex rounded-md px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                        <input
                          type="text"
                          name="yourname"
                          id="yourname"
                          // autoComplete="yourname"
                          className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                        />
                      </div>
                      <p className="text-xs italic leading-6 text-gray-600">
                        Please aim for maximum specificity in order to assist us
                        in troubleshooting effectively.
                      </p>
                    </div>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="yourname"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Your name *
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              type="text"
                              name="yourname"
                              id="yourname"
                              onChange={(e) => setName(e.target.value)}
                              // autoComplete="yourname"
                              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                      <div className="sm:col-span-4">
                        <label
                          htmlFor="youremail"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Your email *
                        </label>
                        <div className="mt-2">
                          <div className="flex rounded-md px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                            <input
                              type="text"
                              name="youremail"
                              id="youremail"
                              onChange={(e) => setEmail(e.target.value)}
                              // autoComplete="youremail"
                              className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    {isRegisteringEducationInstitutionsClicked && (
                      <div className="mt-10 grid grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
                        <div className="sm:col-span-4">
                          <label
                            htmlFor="yourOfficialDomain"
                            className="block text-sm font-medium leading-6 text-gray-900"
                          >
                            Your Official Domain *
                          </label>
                          <div className="mt-2">
                            <div className="flex rounded-md px-1.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-600 sm:max-w-md">
                              <input
                                type="text"
                                name="yourOfficialDomain"
                                id="yourOfficialDomain"
                                onChange={(e) =>
                                  setOfficeDomain(e.target.value)
                                }
                                className="block flex-1 border-0 bg-transparent py-1.5 pl-1 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    )}

                    {/* {selectedOption !== 'push-email' && (
                                        <Listbox value={selected} onChange={setSelected}>
                                            {({ open }) => (
                                                <>
                                                    <Label className="block text-sm font-medium leading-6 text-gray-900">Your government agency *</Label>
                                                    <div className="relative mt-2">
                                                        <ListboxButton className="relative w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6">
                                                            <span className="block truncate">{selected.name}</span>
                                                            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                                                                <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                                                            </span>
                                                        </ListboxButton>
                                                        <Transition show={open} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
                                                            <ListboxOptions className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                                                {people.map((person) => (
                                                                    <ListboxOption
                                                                        key={person.id}
                                                                        className={({ focus }) =>
                                                                            classNames(
                                                                                focus ? 'bg-indigo-600 text-white' : '',
                                                                                !focus ? 'text-gray-900' : '',
                                                                                'relative cursor-default select-none py-2 pl-8 pr-4',
                                                                            )
                                                                        }
                                                                        value={person}
                                                                    >
                                                                        {({ selected, focus }) => (
                                                                            <>
                                                                                <span className={classNames(selected ? 'font-semibold' : 'font-normal', 'block truncate')}>
                                                                                    {person.name}
                                                                                </span>
                                                                                {selected ? (
                                                                                    <span
                                                                                        className={classNames(
                                                                                            focus ? 'text-white' : 'text-indigo-600',
                                                                                            'absolute inset-y-0 left-0 flex items-center pl-1.5',
                                                                                        )}
                                                                                    >
                                                                                        <CheckIcon className="h-5 w-5" aria-hidden="true" />
                                                                                    </span>
                                                                                ) : null}
                                                                            </>
                                                                        )}
                                                                    </ListboxOption>
                                                                ))}
                                                            </ListboxOptions>
                                                        </Transition>
                                                    </div>
                                                </>
                                            )}
                                        </Listbox>
                                    )} */}
                    {!isRegisteringEducationInstitutionsClicked && (
                      <div className="my-8 mt-10">
                        <label className="text-base font-semibold leading-6 text-gray-900">
                          Your government agency *{" "}
                          {selectedAgency && (
                            <p className="text-sm font-normal">
                              Selected Agency: {selectedAgency}
                            </p>
                          )}
                        </label>
                        <select
                          onChange={(e) => setSelectedAgency(e.target.value)}
                          className="relative mt-4 w-full cursor-default rounded-md bg-white py-1.5 pl-3 pr-10 text-left text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:outline-none focus:ring-2 focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        >
                          {agencies.map((agency, index) => (
                            <option key={index} value={agency}>
                              {agency}
                            </option>
                          ))}
                        </select>
                      </div>
                    )}

                    {/* <div className="col-span-full">
                      <label className="block text-base font-medium font-semibold leading-6 text-gray-900">
                        Terms and Conditions *
                      </label>
                      <div className="mt-5 rounded-lg border-2 p-3 text-sm shadow-inner">
                        <p>
                          By using Go.NUG, you agree to the following terms and
                          conditions:
                        </p>
                        <br />
                        <p>
                          1. Data Collection: In addition to the voluntary
                          provision of your name and email address, Go.NUG may
                          automatically collect and store your IP address,
                          browser information, operating system details, and
                          country during your interaction with the platform.
                        </p>
                        <br />
                        <p>
                          2. Encryption: For security purposes, all collected
                          data, including your IP address, will be encrypted
                          using industry-standard encryption protocols during
                          the submission process.
                        </p>
                        <br />
                        <p>
                          3. Storage: The encrypted data, including your IP
                          address, will be securely stored in a dedicated
                          database to which only authorized personnel have
                          access.
                        </p>
                        <br />
                        <p>
                          4. Data Usage: The provided information, including
                          your IP address, will be used solely for the intended
                          purpose, such as unique data collection and system
                          analysis. We will not share your IP address or any
                          other collected data with any third parties, including
                          government officials, without your explicit consent,
                          unless required by law.
                        </p>
                        <br />
                        <p>
                          5. Security Measures: We implement reasonable and
                          appropriate security measures to safeguard all
                          collected and encrypted information, including your IP
                          address, against unauthorized access, disclosure,
                          alteration, and destruction.
                        </p>
                        <br />
                        <p>
                          6. Retention Period: The collected and encrypted data,
                          including your IP address, will be retained for the
                          duration necessary to fulfill the purpose for which it
                          was collected, or as required by applicable laws and
                          regulations.
                        </p>
                        <br />
                        <p>
                          7. Your Rights: In addition to the rights related to
                          name and email data, you have the right to request
                          access, correction, or deletion of your IP address and
                          other collected information. To exercise these rights
                          or for any concerns regarding your data, please
                          contact us using the provided contact information.
                        </p>
                        <br />
                        <p>
                          8. Consent: By using Go.NUG, you consent to the
                          collection, encryption, and storage of your name,
                          email address, IP address, and other relevant
                          information in accordance with these terms and
                          conditions.
                        </p>
                        <br />
                        <p>
                          9. Updates: These terms and conditions, including the
                          data collection and usage related to IP addresses, may
                          be updated from time to time, and any changes will be
                          communicated to you through the contact information
                          provided.
                        </p>
                        <br />
                        <p>
                          Please read these terms and conditions carefully
                          before using Go.NUG. If you do not agree with any of
                          the terms, please refrain from using Go.NUG.
                        </p>
                        <br />
                      </div>
                    </div> */}
                  </div>
                </fieldset>
              </div>
            </div>
          </div>
          <div className="my-8 flex h-6 items-center">
            <input
              id="acceptterms"
              aria-describedby="acceptterms-description"
              name="acceptterms"
              type="checkbox"
              onClick={handleClick}
              className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
            />
            {accepted ? "" : ""}

            <div className="ml-2 text-sm leading-6">
              <label
                htmlFor="acceptterms"
                className="font-medium text-gray-900"
              >
                I've accept these <a href="/privacy-policy" className="underline">terms and conditions</a>
              </label>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-end gap-x-6">
            <button
              type="submit"
              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Submit
            </button>
          </div>
        </div>
      </form>

      <Footer />
    </div>
  );
}
