import React, { useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Fragment } from "react";
import remembering from "../../assets/remembering.svg";
import './ourTeams.css';

const keyStakeholder = [
  {
    name: "Joe S",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/JoeS.png"
  },
  {
    name: "Katherine",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/katherine.png"
  },
  {
    name: "Hermione",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/hermione.png"
  }
];

const people = [
  {
    name: "Dave",
    role: "Project Lead",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/dave.png"
  },
  {
    name: "David",
    role: "Lead Software Engineer",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/david.png"
  },
  {
    name: "Tyler",
    role: "Software Engineer",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/tyler.png"
  },
  {
    name: "Jian",
    role: "Software Engineer",
    imageUrl: "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/jian.png"
  },
  {
    name: "Lumière",
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/lumie%CC%80re.png"
  },
  {
    name: "Yukio",
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/noprofile.png"
  },
  {
    name: "SL",
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/noprofile.png"
  },
  {
    name: "Ryan A",
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/noprofile.png"
  },
  {
    name: "Julio",
    info: "Remembering",
    infoShort: `Julio was a dedicated software engineer who was passionate about creating innovative solutions that enhance government operations and improve citizen engagement. He was a valued member of the SmartGov team and will be greatly missed.`,
    infoLong: `Our Deepest Condolences and Tribute

It is with great sorrow that we share the news of the passing of one of our valued team members. We are deeply saddened by this loss. He was not only a dedicated part of our team but also one of the key contributors to the transformation of NUG websites, giving his best efforts to make a meaningful impact.

His dedication and hard work will always be remembered, and his contributions will continue to inspire us as we move forward. In this time of grief, we stand together in honoring his legacy and committing to carrying on the work he was so passionate about.

Our heartfelt condolences go out to his family and loved ones. May he rest in peace.

SmartGov Team`,
    role: "Software Engineer",
    imageUrl:
      "https://cdn.egov.nugmyanmar.org/assets/smartgov/avatar/julio.png"
  }
  // More people...
];

export default function OurTeams() {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState(null);

  function openModal(person) {
    setSelectedPerson(person);
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
    setSelectedPerson(null);
  }

  return (
    <div className="pt-24 sm:pt-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl lg:mx-0">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Innovators at Work
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            We are a dedicated team of IT professionals committed to
            revolutionizing public service through smart technology. Our team
            comprises IT professionals in software development, data analytics,
            cybersecurity, and user experience design, all working together to
            create innovative solutions that enhance government operations and
            improve citizen engagement.
          </p>
        </div>
        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">
          Leading Agency / Key Stakeholder
        </h2>
        <ul
          role="list"
          className="mx-auto mt-10 grid max-w-2xl grid-cols-3 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
        >
          {keyStakeholder.map((keyStakeholder) => (
            <li key={keyStakeholder.name}>
              <img
                className="mx-auto h-24 w-24 rounded-full"
                src={keyStakeholder.imageUrl}
                alt=""
              />
              <h3 className="mt-6 text-base font-semibold leading-7 tracking-tight text-gray-900">
                {keyStakeholder.name}
              </h3>
              {/* <p className="text-sm leading-6 text-gray-600">{person.role}</p> */}
            </li>
          ))}
        </ul>
        <h2 className="mt-10 text-2xl font-bold tracking-tight text-gray-900">
          Engineer
        </h2>
        <ul
          role="list"
          className="mx-auto mt-10 grid max-w-2xl grid-cols-3 gap-x-8 gap-y-16 text-center sm:grid-cols-3 md:grid-cols-4 lg:mx-0 lg:max-w-none lg:grid-cols-5 xl:grid-cols-6"
        >
          {people.map((person) => (
            <li key={person.name}>
              <img
                className="mx-auto h-24 w-24 rounded-full"
                src={person.imageUrl}
                alt={person.name}
              />
              {person.info && (
                <p
                  className="mt-6 text-xs leading-6 text-gray-600 cursor-pointer shimmerText"
                  onClick={() => openModal(person)}
                >
                  {person.info === "Remembering" && (
                    <img
                      src={remembering}
                      alt="Remembering"
                      className="inline-block w-6 h-6"
                    />
                  )}{" "}
                  {person.info}
                </p>
              )}
              <h3
                className={`text-base font-semibold leading-7 tracking-tight text-gray-900 ${person.info ? "mt-0" : "mt-6"
                  }`}
              >
                {person.name}
              </h3>
              <p className="text-sm leading-6 text-gray-600">{person.role}</p>
            </li>
          ))}
        </ul>
      </div>

      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="h3"
                    className="text-lg font-medium leading-6 text-gray-900 flex justify-between items-center"
                  >
                    <p>
                      <span className="shimmerText">Remembering</span> {selectedPerson?.name}
                    </p>
                    <img
                      src={remembering}
                      alt="Remembering"
                      className="inline-block w-12 h-12"
                    />
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      {selectedPerson?.infoLong.split('\n').map((line, index) => (
                        <p key={index} className="text-sm text-gray-500 mt-2">
                          {line}
                        </p>
                      ))}
                    </p>
                  </div>

                  <div className="mt-4">
                    <button
                      type="button"
                      className="w-full inline-flex justify-center rounded-md border border-transparent bg-gray-100 px-4 py-2 text-sm font-medium text-gray-900 hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-blue-500 focus-visible:ring-offset-2"
                      onClick={closeModal}
                    >
                      Close
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
}