import React from "react";
import Header from "../../components/Header/header";
import Testimonial from "../../components/Testimonial/testimonial";
import Navbar from "../../components/Navbar/navbar";
import Footer from "../../components/Footer/footer";

export default function successStories() {
  return (
    <div>
      <Navbar />
      <Header
        title="Success Stories"
        description="Discover how SmartGov is transforming governance through our officier success stories."
      />
      <Testimonial />
      <Footer />
    </div>
  );
}
