import React from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/navbar";
import Header from "../../components/Header/header";
import Footer from "../../components/Footer/footer";
import ValueList from "../../components/OurValueList/ourValueList";
import OurTeams from "../../components/OurTeams/ourTeams";
import OurTeamsSay from "../../components/ourTeamSay/ourTeamSay";

export default function About() {
  return (
    <>
      <Helmet>
        <title>About | SmartGov</title>
      </Helmet>
      <Navbar />
      <Header
        title={"About Us"}
        description={"A tech team working on smart government sector"}
      />
      <div className="relative mt-[-26%] overflow-hidden pt-16 sm:mt-[-12%] lg:pt-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <img
            className="mb-[-12%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
            src="https://cdn.egov.nugmyanmar.org/assets/smartgov/smartgov-intro.svg"
            alt="SmartGov"
          />
          <div className="relative" aria-hidden="true">
            <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
          </div>
        </div>
      </div>

      <div className="bg-white py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none">
            <div className="mx-auto max-w-2xl lg:mx-0">
              <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                About SmartGov
              </h1>
              <h3 className="mt-4 text-lg font-bold tracking-tight text-gray-900 sm:text-xl">
                We are engineers, innovators, and problem solvers.
              </h3>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                Together, we can build a smarter, more efficient government that
                truly serves its citizens. Welcome to the future of government
                infrastructure with SmartGov.
              </p>
            </div>

            <ValueList />

            <div className="mx-auto max-w-3xl lg:mx-0">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Our Values
              </h2>
              <p className="mt-6 text-lg leading-8 text-gray-600">
                <ul>
                  <li>
                    <strong>Empowering Smart Government:</strong> We are
                    dedicated to empowering government infrastructure through
                    innovative and secure solutions that make a difference.
                  </li>
                  <li>
                    <strong>Integrity:</strong> Our commitment to integrity
                    ensures that every solution we provide is reliable, secure,
                    and transparent.
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
        <OurTeams />
        <OurTeamsSay />
      </div>

      <Footer />
    </>
  );
}
